<template>
	<div class="video-html">
		<video
			ref="video"
			:src="src"
			class="video-html-player"
			playsinline
			:autoplay="autoplay"
			:muted="muted"
			:loop="loop"
			:poster="poster"
			:controls="controls"
			v-bind="$attrs"
			@click="togglePlayPause"
		></video>

		<!-- Poster (video still-frame) & play-button -->
		<div
			v-if="poster && posterVisible && !controls && !autoplay"
			ref="poster"
			@click="togglePlayPause"
			class="video-poster"
		>
			<TnIcon
				name="play-circle-outline"
				class="video-poster-button"
				:class="{ darkIcons }"
			/>
		</div>
	</div>
</template>

<script>
import { defineComponent } from "vue";

import commonProps from "./commonProps";

export default defineComponent({
	name: "TnVideoHtml",

	props: commonProps,

	data() {
		return {
			posterVisible: true,
			paused: this.autoplay,
		};
	},

	watch: {
		autoplay() {
			if (this.autoplay) {
				this.playVideo();
			} else {
				this.pauseVideo();
			}
		},
	},

	mounted() {
		const videoEl = this.$refs.video;
		if (videoEl?.readyState > 0) {
			this.$parent.$emit("metadata-ready");
		} else {
			videoEl.onloadedmetadata = () => {
				this.$parent.$emit("metadata-ready");
			};
		}
		if (!this.autoplay) {
			this.pauseVideo();
		}
	},

	methods: {
		togglePlayPause() {
			this.$refs["video"].paused ? this.playVideo() : this.pauseVideo();
		},
		playVideo() {
			this.$refs["video"].play();
			this.paused = false;
			this.posterVisible = false;
		},
		pauseVideo() {
			this.$refs["video"].pause();
			this.paused = true;
			this.posterVisible = true;
		},
		getVideoWidth() {
			return this.$refs["video"].videoWidth || null;
		},
		getVideoHeight() {
			return this.$refs["video"].videoHeight || null;
		},
		gcdAlgorithm(a, b) {
			return b === 0 ? a : this.gcdAlgorithm(b, a % b);
		},
		getAspectRatioFloat() {
			return this.getVideoWidth() / this.getVideoHeight();
		},
		getAspectRatio() {
			const w = this.getVideoWidth();
			const h = this.getVideoHeight();
			const r = this.gcdAlgorithm(w, h);
			return w / r + ":" + h / r;
		},
	},
});
</script>

<style lang="scss" scoped>
@use "style";

.video-html {
	position: relative;
	display: inline-block;
	width: 100%;
	height: auto;

	&-player {
		top: 0;
		left: 0;
		width: 100%;
		height: auto;
	}
}
</style>
