<template>
	<div class="video-youtube">
		<div
			class="video-youtube-player"
			ref="video"
			:data-url="videoId"
			:data-autoplay="autoplay"
			:data-loop="loop"
			:data-controls="controls"
			data-remove-recomendations="true"
		></div>

		<!-- Poster (video still-frame) & play-button -->
		<div
			v-if="posterVisible"
			ref="poster"
			@click="handlePosterClicked"
			:id="`video-youtube-poster-${videoId}`"
			class="video-poster"
			:class="{ loading }"
			:style="srcPoster"
		>
			<TnIcon
				name="play-circle-outline"
				class="video-poster-button"
				:class="{ darkIcons }"
			/>
		</div>
	</div>
</template>

<script>
import { defineComponent } from "vue";

import commonProps from "./commonProps";

export default defineComponent({
	name: "TnVideoYoutube",

	props: commonProps,

	data() {
		return {
			player: null,
			paused: this.autoplay,
			loading: false,
			videoId: this.src || null,
			posterVisible: !this.autoplay,
			youtubeCallbackName: "onYouTubeIframeAPIReady",
			youtubeExistsFlag: "$isYoutubeFrameAPIReady",
		};
	},

	computed: {
		srcYoutubeID() {
			if (this.videoId.indexOf(":/") !== -1) {
				const catcher = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/i;
				const res = catcher.exec(this.videoId);
				if (res && res[1]) return res[1];
			}
			return this.videoId;
		},
		srcPoster() {
			const url = this.poster || `https://img.youtube.com/vi/${this.srcYoutubeID}/hqdefault.jpg`;
			return `background-image: url('${url}')`;
		},
	},

	async mounted() {
		if (this.autoplay) {
			await this.initPlayer();
			if (this.autoplay && this.player) {
				await this.player.playVideo();
			}
		}
	},

	methods: {
		async initPlayer() {
			if (!this.hasYoutubeFrameAPI()) {
				this.injectYoutubeFrameAPI();
			}
			await this.whenYoutubeAPIReady();
			const player = this.$refs["video"];
			if (player) {
				player.id = `player-${Math.floor(Math.random() * 1024)}-${Date.now()}-${Math.floor(Math.random() * 1024)}`;

				return new Promise((resolve) => {
					new YT.Player(player, {
						host: "https://www.youtube-nocookie.com",
						videoId: this.srcYoutubeID,
						playerVars: {
							autoplay: this.autoplay ? 1 : 0,
							controls: this.controls ? 1 : 0,
							loop: this.loop ? 1 : 0,
							mute: this.muted ? 1 : 0,
							rel: 0,
							autohide: 1,
							disablekb: 1,
							iv_load_policy: 3,
							modestbranding: 1,
							enablejsapi: 1,
						},
						events: {
							onReady: (event) => {
								this.player = event.target;
								resolve();
							},
							onStateChange: this.onStateChange,
						},
					});
				});
			}
		},
		whenYoutubeAPIReady() {
			const existsFlag = this.youtubeExistsFlag;
			return new Promise(function (resolve, reject) {
				let elapsed = 0;
				let intervalHandle;
				const checker = function () {
					elapsed += 48;
					if (window[existsFlag]) {
						clearTimeout(intervalHandle);
						resolve();
					} else {
						if (elapsed <= 15000) {
							intervalHandle = setTimeout(checker, 48);
						} else {
							reject("timeout");
						}
					}
				};
				setTimeout(checker, 48);
			});
		},
		hasYoutubeFrameAPI() {
			if (!this.hasYTFrame) {
				this.hasYTFrame = !!document.getElementsByClassName(".yt-frame-api").length;
			}
			return this.hasYTFrame;
		},
		injectYoutubeFrameAPI() {
			const youtubeExistsFlag = this.youtubeExistsFlag;
			const youtubeCallbackName = this.youtubeCallbackName;

			window[this.youtubeCallbackName] =
				window[this.youtubeCallbackName] ||
				function () {
					window[youtubeExistsFlag] = true;
					window[youtubeCallbackName] = null;
					delete window[youtubeCallbackName];
				};

			const tag = document.createElement("script");
			const first = document.getElementsByTagName("script")[0];
			tag.src = "https://www.youtube.com/iframe_api";
			tag.className = "yt-frame-api";
			first.parentNode.insertBefore(tag, first);
		},
		async playVideo() {
			this.posterVisible = false;
			if (!this.player) await this.initPlayer();
			await this.player.playVideo();
			this.paused = false;
		},
		async pauseVideo() {
			if (!this.player) await this.initPlayer();
			await this.player.pauseVideo();
			this.paused = true;
		},
		togglePlayPause() {
			this.paused ? this.playVideo() : this.pauseVideo();
		},
		async handlePosterClicked() {
			this.loading = true;
			await this.initPlayer();
			this.loading = false;
			this.posterVisible = false;
			await this.player.playVideo();
		},
	},
});
</script>

<style lang="scss" scoped>
@use "style";

.video-youtube {
	position: relative;
	padding-bottom: 56.25%; /* Sets the aspect ratio of the video wrapper to 16:9 */
	padding-top: 0;
	height: 0;
	background: #000;

	:deep(iframe),
	:deep(object),
	:deep(embed) {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
</style>
